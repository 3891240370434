<template>
  <div id="app">

    <VvvIndex />
  </div>
</template>

<script>
import VvvIndex from './page/Index.vue'

export default {
  name: 'App',
  components: {
    VvvIndex
  }
}
</script>

<style>
</style>

<template>
  <div>
    <van-row>
        <van-col span="1"></van-col>
        <van-col span="22">

            <van-swipe class="my-swipe" :autoplay="3000" >
        <van-swipe-item v-for="(image, iindex) in pic_list" :key="iindex">
            <img height="210rem" width="100%"  :src="image" />

        </van-swipe-item>
    </van-swipe>

    <!-- 文字较短时，通过设置 scrollable 属性开启滚动播放 -->
    <van-notice-bar  left-icon="volume-o" scrollable :text="titile" />
    <!-- -->
    <!-- 悬浮按钮 -->
    <!-- <button v-show="isVisible" @click="clickHandler" class="floating-button">点击下载</button> -->

        <van-tabs v-model="active" swipeable @rendered="rendered" @change="this.tabChange" >
            <van-tab v-for="(title,index) in tab_list" :key="index" :title="title">
                <van-card  v-for="(obj,_index) in video_list"
                    :key="_index"

                
                >

                <template #thumb>
                
                    <video    preload="auto" :poster="obj.fUrl"  controls :src="obj.url" style="width: 120px; height: 97px;" ></video>
                </template>
                <template #title>
                    <h3 style="margin-left: 40px;">{{obj.title}} </h3>
                </template>
                <template #desc>
                    <p style="margin-left: 40px;">{{obj.name}} </p>
                </template>
                </van-card>
            </van-tab>
        </van-tabs>
        </van-col>
        <van-col span="1"></van-col>
    </van-row>

  </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload } from 'vant';

Vue.use(Lazyload);

export default {
  name: 'VvvIndex',
  components:{},
  data() {
    return {
        api:"38.55.239.28:6939/system/",
        isVisible: true, // 控制按钮是否显示,
        tab_list:[],
        active: 0,
        titile:"",
        video_list:[],
        pic_list:[],
        
      
    };
  },

  mounted() {

    this.axios.get("http://"+this.api+"title/list2").then((response) => {
        this.titile=response.data.rows[0].title
        console.log(this.titile)
        
    })
    this.get_pic()
    this.get_tab_list()
  },

  methods: {
    clickHandler() {
      // 处理按钮点击事件
      console.log('Floating button clicked!');
    },
    get_pic(){
        this.axios.get("http://"+this.api+"pic/list2").then((response) => {
            for(var i=0;i<response.data.rows.length;i++){
            
                this.pic_list.push(response.data.rows[i].url)
            }
       })
       console.log("pic response.data.data:", this.pic_list);
    },
    get_tab_list(){
        this.axios.get("http://"+this.api+"type/list2").then((response) => {
            for(var i=0;i<response.data.rows.length;i++){
            
                this.tab_list.push(response.data.rows[i].name)
            }
       })
    },
    get_video(title){
       
        this.axios.get("http://"+this.api+"video/list2").then((response) => {
            this.video_list=[]
            for(var i=0;i<response.data.rows.length;i++){
                if(response.data.rows[i].type==title){
                    console.log(response.data.rows[i].type);
                    this.video_list.push(response.data.rows[i])
                }
    
                    
            }

            console.log( "this.video_list",this.video_list)
        })
    },
    rendered(name,title){
        
        this.get_video(title)
        // console.log(title,name);
    },
    tabChange(name,title){
        this.get_video(title)
        console.log(title,name);
    }
    
    
  },
};
</script>

<style  scoped>


 .van-tabs {
        display: block;
        height: 500px;
        touch-action: manipulation;
    }
    .floating-button {
  position: fixed; /* 固定定位 */
  bottom: 20rem;   /* 距离底部20像素 */
  right: 20px;    /* 距离右侧20像素 */
  width: 50px;    /* 按钮宽度 */
  height: 50px;   /* 按钮高度 */
  background-color: #007bff; /* 背景颜色 */
  color: white;   /* 文字颜色 */
  border: none;   /* 无边框 */
  border-radius: 50%; /* 圆形按钮 */
  cursor: pointer; /* 鼠标悬停时显示指针 */
  z-index: 999;   /* 确保按钮在最上层 */
}

</style>
import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import axios from 'axios'
import VueAxios from 'vue-axios'




Vue.use(VueAxios, axios)
import 'vant/lib/index.css';
Vue.config.productionTip = false
Vue.use(Vant);
new Vue({
  render: h => h(App),
}).$mount('#app')
